export const amapLayerColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '图层名称',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '所属地图',
      align: 'center',
      dataIndex: 'mapName',
    },
    {
      title: '父级图层',
      align: 'center',
      dataIndex: 'parentName',
      customRender: (text, record) => {
        const time = `${record.parentName ? record.parentName : '-'}` //es6写法
        return time
      },
    },
    {
      title: '操作',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}

export const amapLayerFormVO = () => {
  return {
    id: '',
    mapInfo: {
      id: '',
    },
    name: '',
    parentId: 0,
    typeLabels: {
      deviceCategoryIds: [],
      facilityTypeIds: [],
      otherOptionIds: [],
    },
  }
}
